<template>
    <section class="subscription-table">
        <div v-if="loading">
            <div class="pixel-loader"></div>
        </div>
        <div v-else>
            <div class="col-12 grid-margin">
                <div class="card">
                    <div class="card-body">
                        <span class="card-title h3">{{title}}</span>
                        <perfect-scrollbar>
                            <div class=" overflow-height">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                            <th v-for="(col, idx) in columns" :key="idx"> {{col}} </th>
                                        </tr>
                                        </thead>
                                        <tbody class="sortable">
                                            <tr v-for="(row, index) in rows" :key="index" v-on:click="openUserDetail({ userName: row[0].value, linkId: row[3].value })">
                                                <td v-for="(data, i) in row.filter(r => r.isVisible)" :key="i">
                                                    <span class="pl-2">{{data.value}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </perfect-scrollbar>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
  name: 'subscription-table',
  components: {
  },
  data() {
    return {
        loading: true,
    };
  },
  props: ["title", "columns", "rows"],
  created () {
    this.loading = false;
  },
  methods: {
    openUserDetail(user) {
        this.$root.$emit('openUserDetailDialog', user);
    }
  }
}
</script>
<style lang="scss">
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}
</style>