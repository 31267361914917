<template>
  <section class="laderboard">
    <div class="page-header">
      <h3 class="page-title">
        {{$t('mySubscription')}}
      </h3>      
    </div>
    <div v-if="loading">
      <div class="pixel-loader"></div>
    </div>
    <div v-else>
      <div class="row" v-if="noSubscription">
        <div class="center-block" >
          <span class="card-title h2">{{$t('noSubscription')}}</span> 
        </div>
      </div>
      <div class="row" v-else>
        <subscription-table :columns="columns" :rows="rows" />
      </div>
    </div>
    <UserDetail />
  </section>
</template>

<script>
import UserDetail from "@/pages/dialogs/userDetail";
import subscriptionTable from '@/pages/share/subscription-table';

export default {
  name: 'leaderboard',
  components: {
    UserDetail,
    subscriptionTable
  },
  data() {
    return {
      loading: true,
      columns: [],
      rows: [],
      subscriptions: []
    };
  },
  computed: {
    noSubscription(){
      return this.rows.length == 0;
    }
  },
  created () {
    this.$bobwinHub.$on('subscrioptionsReceived', this.subscrioptionsReceived);
    this.init();
  },
  beforeDestroy () {
    this.$bobwinHub.$off('subscrioptionsReceived', this.subscrioptionsReceived);
  },
  methods: {
    init () {
        this.getSubscriptions();
    },
    getSubscriptions() {
        this.loading = true;
        this.CallHub({task: 'GetSubscriptions', callback: 'subscrioptionsReceived'});
    },
    subscrioptionsReceived (response) {
      const subscriptions = JSON.parse(response.data);
      this.columns = ['Nickname', 'Start Date', 'End Date'];
      this.rows = [];
      if (subscriptions.length > 0) {
        subscriptions.forEach(s => {
            const row = [
              this.structRowData(s.contributorNickname, true), 
              this.structRowData(this.GetFormattedDatetime(s.startDate), true), 
              this.structRowData(this.GetFormattedDatetime(s.endDate), true),
              this.structRowData(s.linkId, false)
            ];
            this.rows.push(row);
        });
      }
      this.loading = false;
    },
    structRowData(val, isVisible) {
      return {
        isVisible: isVisible,
        value: val
      }
    },
    getUtcNow() {
      const date = new Date();
      const now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
      return new Date(now_utc);
    },
    getMondayDate () {
      const today = this.getUtcNow();
      const dayOfWeek = today.getUTCDay();
      const diff = today.getUTCDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
      const utcMonday = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), diff, 0, 0, 0);
      const monday = new Date(utcMonday);
      return monday;
    },
    addDaysToDate(date, days) {
      const result = new Date(date);
      result.setUTCDate(result.getUTCDate() + days);
      return result;
    },
    getDashboard () {
    },
    onLeaderBoardReceived () {
    },
  }
}
</script>


<style lang="scss" scoped>
.overflow-height {
  height: 500px;
}
.sortable tr {
  cursor: pointer;
}

</style>